<template>
  <div class="newsPage">
    <Loading v-if="!loaded"></Loading>
    <div class="noNews" v-if="!news && loaded">
      {{t('There aren\'t Any News')}}
    </div>
    <div class="news lmt imageList" v-if="news && loaded">
      <router-link :to="{ name: 'NewsItem', params: { slug: item.slug} }" class="article mmt" v-for="(item, index) in news" :key="item.id">
        <img class="img-responsive" v-if="item.image.sizes.large" :src="item.image.sizes.large">
        <div class="title">{{item[`title-${language}`]}}
          <a v-bind:class="{'expandContentBtn': !expanded[index], 'contractContentBtn': expanded[index]}" @click="toggleContent(index)"><span class="hidden">expandir</span></a>
        </div>

        <div v-show="expanded[index]" v-html="item[`text-${language}`]"></div>
      </router-link>
    </div>
    <Pagination v-if="loaded" :page="currentPage" :pages="parseInt(pages,10)" v-on:goToPage="goToPage"/>
  </div>
</template>


<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import Loading from '../Loading';
import Pagination from '../Pagination/Pagination'

export default {
  name: 'NewsComponent',
  components: {
    Loading,
    Pagination
  },
  data() {
    return {
      expanded: {},
    };
  },
  computed: {
    ...mapState({
      news: state => state.news.list,
      language: state => state.language,
      loaded: state => state.news.loaded,
      pages: state => state.news.totalPages
    }),
  currentPage() {
    if(typeof this.$route.query.page === 'undefined') {
      return 1
    } else if (isNaN(this.$route.query.page)) {
      return 1
    } else {
      return parseInt(this.$route.query.page, 10); 
    } 
  }
  },
  methods: {
    ...mapActions({
      getNews: 'news/get',
    }),
    toggleContent(index) {
      if (typeof this.expanded === 'undefined') {
        Vue.set(this.expanded, index, false);
      }
      Vue.set(this.expanded, index, !this.expanded[index]);
    },
    goToPage(p) {
      this.$router.push({component: 'news', query: {page: p}})
      this.getNews(p)
    }
  },
  created() {
    let p = this.$route.query.page
    if(typeof p === 'undefined') {
      p = 1
    }
    this.getNews(p);
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/variables.less";

.imageList {
  float: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
  grid-gap: 8px;

  .article {
    width: 100%;
  }

		.title {
			color: @brand-primary;
			padding-left: 2px;
			padding-top: 6px;
			padding-bottom: 6px;
			max-height: 34px;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

		}

	.article:hover {
		text-decoration: none;
		.title {
			background-color: @brand-primary;		
			color: white;
			border-bottom: 0;
		}
	}
}

.img-responsive {
  aspect-ratio: 930 / 523;
  width: 100%;
  object-fit: cover;
}

</style>
