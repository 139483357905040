<template>
  <div>

  </div>
</template>


<script>

export default {
  name: 'AdminComponent',
  created() {
    window.location = `${process.env.API_URL}/wp-admin`;
  },
};
</script>

